@import '../../application/index';

:root {
  .mat-tab-nav-bar {
    border-bottom: 0;

    .mat-ink-bar {
      background-color: var(--ds-green-500);
    }
  }

  .mat-tab-link {
    @include body-text();
    min-width: 0;
    height: 39px;
    padding: 0 16px;
    opacity: 1;
    color: var(--ds-gray-900);
    font-weight: var(--font-weight-medium);

    &.mat-focus-indicator {
      &:hover {
        color: var(--ds-black);
        text-decoration: none;
      }
    }

    &.mat-tab-label-active {
      color: var(--ds-black);
      text-decoration: none;
    }

    &.mat-tab-disabled {
      opacity: 50%;
    }
  }
}
