@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin small-caps {
  color: var(--ds-gray-900);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

@mixin marketing-link {
  display: flex;
  align-items: center;
  min-width: 80px;
  height: var(--grid-4x);
  padding: 0 var(--grid-2x);
  color: var(--ds-white);
  border: none;
  outline: 0;
  background: var(--ds-green-500);
  font-weight: var(--font-weight-medium);
  text-decoration: none;

  &:hover {
    background: var(--ds-green-700);
  }
}

@mixin word-wrap {
  white-space: normal;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
}

@mixin disable-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  // IE10+
  -o-user-select: none;
  user-select: none;
}

/* stylelint-disable order/order, order/properties-order */
/// Returns a block of styles appropriate to render the "body" styled font.
/// @param {string} $variant ['default'] - The color variant key. Must be one of ['default', 'link'].
/// @param {boolean} $bold [false] - The bold flag, used to drive the font-weight styles.
@mixin body-text($variant: 'default', $bold: false) {
  margin: 0;
  color: var(--ds-gray-1200);
  font-family: var(--font-family-base);
  font-size: 12px;
  font-weight: var(--font-weight-regular);
  line-height: 16px;
  letter-spacing: 0;

  // This will apply the correct styles for the 'link' variation
  @if $variant == 'link' {
    color: var(--ds-blue-600);
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @if $bold {
    font-weight: var(--font-weight-medium);
  }
}
/* stylelint-enable order/order, order/properties-order */

/// Returns a block of styles appropriate to render the "caption" styled font.
@mixin caption-text() {
  margin: 0;
  color: var(--ds-gray-1200);
  font-family: var(--font-family-base);
  font-size: 10px;
  font-weight: var(--font-weight-regular);
  line-height: 12px;
  letter-spacing: 0;
}

/// Returns a block of styles appropriate to render the "hero" styled font.
@mixin hero-text() {
  margin: 0;
  color: var(--ds-gray-1200);
  font-family: var(--font-family-base);
  font-size: 22px;
  font-weight: var(--font-weight-regular);
  line-height: 26px;
  letter-spacing: 0;
}

/// Returns a block of styles appropriate to render the "section" styled font.
@mixin section-text() {
  margin: 0;
  color: var(--ds-gray-900);
  font-family: var(--font-family-base);
  font-size: 11px;
  font-weight: var(--font-weight-regular);
  line-height: 13px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

/// Returns a block of styles appropriate to render the "title" styled font.
@mixin title-text() {
  margin: 0;
  color: var(--ds-gray-1200);
  font-family: var(--font-family-base);
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  line-height: 20px;
  letter-spacing: 0;
}

// Returns a color swatch square style for use in the chart colors
@mixin swatch($px-width) {
  width: $px-width;
  height: $px-width;
  border: 3px solid var(--ds-white);
  border-radius: 5px;
  outline: 1px solid var(--ds-gray-500);
  outline-offset: -2px;
  cursor: pointer;
  stroke-width: 1px;

  &:hover {
    outline: 1px solid var(--ds-gray-800);
  }
}
