:root {
  $mat-select-trigger-height: var(--grid-4x);

  .mat-select-panel-wrap {
    margin-top: $mat-select-trigger-height;
    padding: 0 var(--grid-2x);
  }

  .mat-select-panel {
    border-radius: var(--border-radius-10);

    .mat-option {
      height: var(--grid-3x);
      padding: 0 var(--grid-4x);

      &:hover {
        background: var(--ds-gray-300);
      }
    }
  }

  .mat-select-trigger {
    height: $mat-select-trigger-height;
    padding: 0 var(--grid);
    border: 1px solid var(--ds-gray-500);
    border-radius: var(--border-radius-10);

    &[aria-owns] {
      border-color: var(--ds-blue-400);
    }
  }

  .mat-select-value {
    vertical-align: middle;
  }

  .mat-selected {
    position: relative;
    background: var(--ds-white);
    font-weight: 500;

    &::after {
      position: absolute;
      top: 50%;
      left: calc(var(--grid) * 1.5);
      width: calc(var(--grid) * 0.75);
      height: calc(var(--grid) * 0.75);
      border-radius: 50%;
      background: var(--ds-blue-400);
      content: '';
      transform: translateY(-50%);
    }
  }
}
