.mode-selection-list.mat-selection-list {
  padding: 0;
  color: var(--ds-gray-1100);

  mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    border-color: var(--ds-black);
    background-color: transparent;
  }

  .mat-pseudo-checkbox {
    margin-right: var(--grid);
    border-radius: var(--ds-border-radius-2-to-border-radius-4);
  }

  .mat-checkbox-frame,
  .mat-pseudo-checkbox,
  mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    border-width: 1px;
    border-color: var(--ds-gray-500);
    background-color: var(--ds-white);

    &::after {
      color: var(--ds-black-to-gray-900);
    }
  }

  .mat-pseudo-checkbox-checked::after {
    top: 3px;
    left: 2px;
    color: var(--ds-gray-1100);
  }

  .mat-checkbox-checkmark-path {
    stroke: var(--ds-black-to-gray-900);
  }

  .mat-checkbox-mixedmark {
    background-color: var(--ds-black-to-gray-900);
  }

  label.mat-checkbox-layout {
    margin-bottom: 0;
    font-weight: normal;
  }

  .mat-checkbox {
    padding: 0 var(--grid);
  }

  .mat-list-item.mat-list-option {
    height: var(--grid-3x);
    padding-top: 0;

    &:hover,
    &:focus {
      background: transparent;
    }

    .mat-list-item-content {
      padding: 0;

      .mat-list-text {
        padding: 0;
      }
    }
  }
}
