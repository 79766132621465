// Tippy.js tooltip custom style overrides for webapp

.tippy-box[data-theme~='capra'] {
  &[data-theme~='mini'] .tippy-content,
  &[data-theme~='regular'] .tippy-content,
  &[data-theme~='large'] .tippy-content,
  &[data-theme~='regular-thin'] .tippy-content {
    @include body-text();
  }

  &[data-theme~='mini'] .tippy-content,
  &[data-theme~='regular'] .tippy-content,
  &[data-theme~='regular-thin'] .tippy-content {
    text-align: center;
  }

  &[data-theme~='regular'] .tippy-content,
  &[data-theme~='regular-thin'] .tippy-content,
  &[data-theme~='large'] .tippy-content {
    max-width: 280px;
  }

  &[data-theme~='mini'] .tippy-content {
    max-width: 160px;
    padding: 8px;
  }

  &[data-theme~='regular-thin'] .tippy-content {
    padding: 4px;
  }

  &[data-theme~='regular'] .tippy-content {
    padding: 12px 16px;
  }

  &[data-theme~='large'] .tippy-content {
    padding: 16px 24px;
  }

  &[data-theme~='mini'] .tippy-content,
  &[data-theme~='regular-thin'] .tippy-content,
  &[data-theme~='regular'] .tippy-content,
  &[data-theme~='large'] .tippy-content {
    color: var(--ds-white);
    border-radius: var(--border-radius-2);
    background-color: var(--ds-product-tooltip-background-color);
  }

  // color the arrows for the top and bottom positions
  &[data-placement^='top'] {
    .tippy-arrow::before {
      border-top-color: var(--ds-product-tooltip-background-color);
    }
  }
  &[data-placement^='bottom'] {
    .tippy-arrow::before {
      border-bottom-color: var(--ds-product-tooltip-background-color);
    }
  }
  &[data-placement^='left'] {
    .tippy-arrow::before {
      border-left-color: var(--ds-product-tooltip-background-color);
    }
  }
  &[data-placement^='right'] {
    .tippy-arrow::before {
      border-right-color: var(--ds-product-tooltip-background-color);
    }
  }
}
