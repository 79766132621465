/*
  Styling for the custom class defined in tne ToastrModule. This positions
  all toast instances in the upper right corner of the viewport
*/

.mode-toast-position {
  top: 31px;
  right: 20px;
  pointer-events: auto;
}
