mat-checkbox {
  .mat-checkbox-background {
    width: 16px;
    height: 16px;
    border: 1px solid var(--ds-gray-500);
    border-color: var(--ds-gray-500) !important;
    border-radius: var(--border-radius-2);
  }

  .mat-checkbox-checkmark {
    top: 2px;
    left: 2px;
    width: 70%;
    color: var(--ds-gray-900) !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: var(--ds-black) !important;
  }

  .mat-checkbox-frame {
    border: 1px solid var(--ds-gray-500);
    border-radius: var(--border-radius-2);
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent !important;
}
