// Legacy Grunticon icons
@import 'legacy/sprites/icons.data.svg';

// Peer dependencies for Capra tooltip component
@import 'tippy.js/dist/tippy';

// Angular Material component base styles
@import '@angular/material/legacy-prebuilt-themes/legacy-indigo-pink';

// Algolia autocomplete default theme
@import '@algolia/autocomplete-theme-classic';

// ui-select (AngularJS Select2) base styles
@import 'ui-select/dist/select';

// System font family library
@import 'system-font-css/system-font';

// Third Party vendor styles
@import 'vendor/index';

// Global classes
.mode-header {
  font-family: var(--font-family-header);
}

.mode-nav-header {
  font-family: var(--font-family-header);
  font-size: 13px;
  text-transform: none !important;
}

.mode-headline-1 {
  font-family: var(--font-family-header);
  font-size: 70px;
  line-height: 90%;
  letter-spacing: -3%;
}

.mode-headline-2 {
  font-family: var(--font-family-header);
  font-size: 56px;
  line-height: 100%;
  letter-spacing: -3%;
}

.mode-headline-3 {
  font-family: var(--font-family-header);
  font-size: 36px;
  line-height: 103%;
  letter-spacing: -2%;
}

.mode-headline-4 {
  font-family: var(--font-family-header);
  font-size: 28px;
  line-height: 103%;
  letter-spacing: -2%;
}

.mode-th {
  color: var(--ds-gray-1200);
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
}

.mode-label {
  display: block;
  margin-bottom: var(--grid-half);
  color: var(--ds-gray-1200);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
}

.mode-input {
  width: 100%;
  height: var(--grid-4x);
  padding: var(--grid-half) var(--grid);
  border: 1px solid var(--ds-gray-500);
  border-width: 1px;
  border-radius: var(--ds-input-border-radius);
  outline: none;

  :disabled {
    background: var(--ds-gray-200);
  }

  &:focus {
    border-color: var(--ds-blue-300-to-blue-500);
  }

  &::placeholder {
    color: var(--ds-gray-800);
  }

  &-error {
    border-color: var(--ds-status-error-dark);
  }
}

.mode-textarea {
  width: 100%;
  height: calc(var(--grid) * 11);
  padding: var(--grid-half) var(--grid);
  border: 1px solid var(--ds-gray-500);
  border-width: 1px;
  border-radius: var(--ds-input-border-radius);
  outline: none;
  resize: vertical;

  :disabled {
    background: var(--ds-gray-200);
  }

  &:focus {
    border-color: var(--ds-blue-500);
  }

  &::placeholder {
    color: var(--ds-gray-800);
  }

  &-error {
    border-color: var(--ds-status-error-dark);
  }
}

.mode-select {
  height: 24px;
  padding-right: 24px;
  padding-left: 6px;
  color: var(--ds-gray-900);
  border: 1px solid var(--ds-gray-400);
  // important is needed to override legacy css selectors
  border-radius: var(--ds-border-radius-2-to-border-radius-8) !important;
  background: var(--ds-white);
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2012%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%20%20%20%20%20%20%20%20%3Ctitle%3Ecaret-gray%3C%2Ftitle%3E%20%20%20%20%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%20%20%20%20%3Cg%20id%3D%22Style-Guide%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Artboard%22%20transform%3D%22translate%28-101.000000%2C%20-171.000000%29%22%20fill%3D%22%2363656E%22%3E%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M107.774212%2C178.686718%20C107.347098%2C179.10268%20106.658192%2C179.106171%20106.227493%2C178.686718%20L103.215287%2C175.753166%20C102.788172%2C175.337204%20103.01229%2C175%20103.748293%2C175%20L110.253413%2C175%20C110.974898%2C175%20111.217118%2C175.333713%20110.786419%2C175.753166%22%20id%3D%22caret-gray%22%3E%3C%2Fpath%3E%20%20%20%20%20%20%20%20%3C%2Fg%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: calc(100% - 6px) center;
  line-height: 1;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    border-color: var(--ds-blue-400);
    outline: none;
  }
}

.mode-validation-error {
  margin: 0;
  color: var(--ds-red-500);
  font-size: var(--font-size-small);
}

/*
  This class can be applied to any dialog header across the app to
  style it uniformly. However, the preference should be to implement
  the dialog in Angular and use the `DialogHeaderComponent`.
*/
.mode-dialog-header-legacy {
  margin: 0 !important;
  color: var(--ds-gray-1200) !important;
  font-family: var(--font-family-header) !important;
  font-size: 20px !important;
  font-weight: 400 !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    color: var(--ds-gray-1200) !important;
    font-family: var(--font-family-header) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }
}

/*
  Default Checkbox Input Light
*/
.checkbox-icon:not(#foo) > input[type='checkbox']:checked:disabled + label.icon-editor-checkbox-light-unchecked,
.checkbox-icon:not(#foo) > input[type='radio']:checked:disabled + label.icon-editor-checkbox-light-unchecked,
.icon-editor-checkbox-light-checked-disabled {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22%23F8F9F7%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.67157%207.20709L4.25736%208.62131L5.67157%2010.0355L7.08579%2011.4497L8.5%2010.0355L12.7426%205.79288L11.3284%204.37867L7.08579%208.62131L5.67157%207.20709Z%22%20fill%3D%22%23DFE2DE%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%23EAEDE8%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

.checkbox-icon:not(#foo) > input[type='checkbox']:checked + label.icon-editor-checkbox-light-unchecked,
.checkbox-icon:not(#foo) > input[type='radio']:checked + label.icon-editor-checkbox-light-unchecked,
.icon-editor-checkbox-light-checked {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.67157%207.20709L4.25736%208.62131L5.67157%2010.0355L7.08579%2011.4497L8.5%2010.0355L12.7426%205.79288L11.3284%204.37867L7.08579%208.62131L5.67157%207.20709Z%22%20fill%3D%22%236C6E6B%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%23DFE2DE%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

.checkbox-icon:not(#foo) > input[type='checkbox'].partially-selected + label.icon-editor-checkbox-light-unchecked,
.checkbox-icon:not(#foo) > input[type='radio'].partially-selected + label.icon-editor-checkbox-light-unchecked,
.icon-editor-checkbox-light-multiple {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22white%22%2F%3E%3Crect%20x%3D%224%22%20y%3D%227%22%20width%3D%228%22%20height%3D%222%22%20fill%3D%22%236C6E6B%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%23DFE2DE%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

.icon-editor-checkbox-light-unchecked {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22white%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%23DFE2DE%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

/*
  Default Checkbox Input Dark
*/

.checkbox-icon:not(#foo) > input[type='checkbox']:checked:disabled + label.icon-editor-checkbox-dark-unchecked,
.checkbox-icon:not(#foo) > input[type='radio']:checked:disabled + label.icon-editor-checkbox-dark-unchecked,
.icon-editor-checkbox-dark-checked-disabled {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22%235A5C58%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.67158%207.20709L4.25736%208.62131L5.67158%2010.0355L7.08579%2011.4497L8.5%2010.0355L12.7426%205.79288L11.3284%204.37867L7.08579%208.62131L5.67158%207.20709Z%22%20fill%3D%22%236C6E6B%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%236C6E6B%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

.checkbox-icon:not(#foo) > input[type='checkbox']:checked + label.icon-editor-checkbox-dark-unchecked,
.checkbox-icon:not(#foo) > input[type='radio']:checked + label.icon-editor-checkbox-dark-unchecked,
.icon-editor-checkbox-dark-checked {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22%235A5C58%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.67203%207.20712L4.25781%208.62134L7.08624%2011.4498L7.08637%2011.4496L7.08646%2011.4497L12.7433%205.79288L11.3291%204.37866L7.08633%208.62143L5.67203%207.20712Z%22%20fill%3D%22%23F8F9F7%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%237F827E%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

.icon-editor-checkbox-dark-multiple {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22%235A5C58%22%2F%3E%3Crect%20x%3D%224%22%20y%3D%227%22%20width%3D%228%22%20height%3D%222%22%20fill%3D%22%23F8F9F7%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%237F827E%22%2F%3E%3C%2Fsvg%3E%0A');
  background-repeat: no-repeat;
}

.icon-editor-checkbox-dark-unchecked {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20fill%3D%22%235A5C58%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%223.5%22%20stroke%3D%22%237F827E%22%2F%3E%3C%2Fsvg%3E%0A');
  background-repeat: no-repeat;
}

/*
  Default Radio Input
*/
.radio-lg input[type='radio']:checked + label > .icon-radio-inactive,
.radio-icon:not(#foo) > input[type='radio']:checked + label.icon-radio-inactive,
.radio-icon:not(#foo) > input[type='radio']:checked + label > .icon-radio-inactive,
.radio-icon:not(#foo) > input[type='radio']:checked + .icon-radio-inactive,
.icon-radio-active {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%221%22%20y%3D%221%22%20width%3D%2216%22%20height%3D%2216%22%20rx%3D%228%22%20fill%3D%22white%22%20stroke%3D%22%23DFE2DE%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9%2013C11.2091%2013%2013%2011.2091%2013%209C13%206.79086%2011.2091%205%209%205C6.79086%205%205%206.79086%205%209C5%2011.2091%206.79086%2013%209%2013Z%22%20fill%3D%22%237EA6F3%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}

.icon-radio-inactive {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%221.27441%22%20y%3D%220.71582%22%20width%3D%2216%22%20height%3D%2216%22%20rx%3D%228%22%20fill%3D%22white%22%20stroke%3D%22%23DFE2DE%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
}
