.mode-chips-input {
  input.mat-chip-input {
    flex: unset;
    width: 100%;
    margin: 0;
  }
}

.mode-chips {
  display: block;
  overflow: hidden;
  margin-top: var(--grid);

  .mat-chip.mat-standard-chip.mat-chip-with-trailing-icon {
    min-height: 20px;
    padding: 0 var(--grid-quarter) 0 var(--grid-half);
    color: var(--ds-gray-1100);
    border-radius: var(--border-radius-2);
    background: var(--ds-gray-300);
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-regular);

    &:hover {
      background: var(--ds-gray-300);
    }

    &::after {
      display: none;
    }
  }

  .mode-chip-error.mat-chip.mat-standard-chip.mat-chip-with-trailing-icon {
    border: 1px solid var(--ds-red-400);
    background: var(--ds-white);
  }

  .mode-chip-warning.mat-chip.mat-standard-chip.mat-chip-with-trailing-icon {
    border: 1px solid var(--ds-orange-400);
    background: var(--ds-orange-100);
  }

  .mat-standard-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove {
    width: var(--grid-2x);
    height: var(--grid-2x);
    opacity: 1;
    color: var(--ds-gray-900);

    &:hover {
      opacity: 1;
      color: var(--ds-gray-1200);
    }
  }
}
