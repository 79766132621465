.mat-sort-header-pointer-middle {
  width: 0;
  height: 0;
}

.mat-sort-header-arrow {
  .mat-sort-header-indicator {
    &::before {
      margin-top: 12px;
      content: url('../../../../../../../app/assets/sprite_src/icon-sort-ascending.svg');
    }
  }

  .mat-sort-header-pointer-left,
  .mat-sort-header-pointer-right {
    display: none;
  }
  .mat-sort-header-stem {
    display: none;
  }
  // }
}
/* show ascending caret when sorted ascending:*/
[aria-sort='ascending'] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        margin-top: 12px;
        content: url('../../../../../../../app/assets/sprite_src/icon-sort-ascending.svg');
      }
    }
    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right {
      display: none;
    }
    .mat-sort-header-stem {
      display: none;
    }
  }
}
/* show descending caret when sorted descending: */
[aria-sort='descending'] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        margin-top: -5px;
        content: url('../../../../../../../app/assets/sprite_src/icon-sort-descending.svg');
      }
    }
  }
  .mat-sort-header-pointer-left,
  .mat-sort-header-pointer-right {
    display: none;
  }
  .mat-sort-header-stem {
    display: none;
  }
}
