mat-table {
  .mat-cell,
  .mat-header-cell,
  .mat-footer-cell {
    @include body-text();
  }

  .mat-header-cell {
    color: var(--ds-gray-900);
    font-weight: var(--font-weight-medium);
    letter-spacing: 1px;
  }

  mat-header-row {
    min-height: 36px;
  }

  mat-row, mat-header-row, mat-footer-row {
    border-bottom: none;
  }

  mat-row,
  mat-footer-row {
    min-height: 32px;
  }

  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 8px;
  }

  mat-cell:not(:last-of-type),
  mat-header-cell:not(:last-of-type),
  mat-footer-cell:not(:last-of-type) {
    padding-right: 4px;
  }

  mat-cell:last-of-type,
  mat-header-cell:last-of-type,
  mat-footer-cell:last-of-type {
    padding-right: 8px;
  }
}
